import { useEffect } from 'react';
import {
  InstantSearch as AlgoliaInstantSearch,
  useInstantSearch,
} from 'react-instantsearch-hooks-web';
import algoliasearch from 'algoliasearch/lite';
import { debounce } from 'lodash-es';

import { gtmPush } from '@/utils/gtm/helpers';
import { convertToURL } from '../utils';

import type { FunctionComponent } from 'react';
import type { TypeInstantSearchProps } from './types';

const algoliaClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_APP_ID ?? '',
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY ?? ''
);

function gtmMiddleware(results: any) {
  const sendEventDebounced = debounce((uiState: any) => {
    const indexName = Object.keys(uiState)[0];
    const searchQuery = uiState[indexName].query;

    if (searchQuery) {
      if (results.nbHits === 0) {
        gtmPush({
          event: 'search_unavailable',
          search_term: searchQuery,
        });
      } else {
        gtmPush({
          event: 'search',
          search_term: searchQuery,
          search_results_count: results.nbHits,
          search_results: results.hits.map((hit: any) => ({
            item_id: convertToURL(hit.objectID.replaceAll(' > ', '/')),
            item_name: hit.name,
          })),
        });
      }
    }
  }, 3000);

  return {
    onStateChange({ uiState }: any) {
      sendEventDebounced(uiState);
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    subscribe() {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    unsubscribe() {},
  };
}

function Middleware() {
  const { use, results } = useInstantSearch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => use(() => gtmMiddleware(results)));

  return null;
}

/**
 * InstantSearch
 */
export const InstantSearch: FunctionComponent<TypeInstantSearchProps> = ({
  children,
  ...props
}: TypeInstantSearchProps) => (
  <AlgoliaInstantSearch searchClient={algoliaClient} {...props}>
    {children}
    <Middleware />
  </AlgoliaInstantSearch>
);

InstantSearch.displayName = 'InstantSearch';
